import Vue from 'vue'
import VueRouter from 'vue-router'
import useJwt from '@/auth/jwt/useJwt'
import axios from "@axios"


const Login = () => import('@/views/Login.vue')
const ForgotPassword = () => import('@/views/ForgotPassword.vue')
const Register = () => import('@/views/Register.vue')
const Dashboard = () => import('@/views/pages/dashboard/Dashboard.vue')
// const Dashboard = () => import('@/views/pages/dashboard-new/Dashboard.vue') // ambil data efab
const Billing = () => import('@/views/pages/billing/Billing.vue')
const Invoice = () => import('@/views/pages/invoice/Invoice.vue')
// const Invoice = () => import('@/views/pages/invoice-new/Invoice.vue') // ambil data efab 
const Faq = () => import('@/views/pages/faq/Faq.vue')
const Ticket = () => import('@/views/pages/ticket/Ticket.vue')
const Ticketproduct = () => import('@/views/pages/ticket/TicketProduct.vue')
const Tickethistory = () => import('@/views/pages/ticket/TicketHistory.vue')
const Products = () => import('@/views/pages/products/Products.vue')
const Subscribe = () => import('@/views/pages/subscribe/Subscribe.vue')
// const Subscribe = () => import('@/views/pages/subscribe/SubscribeNew.vue') // ambil data efab
const Profile = () => import('@/views/pages/profile/Profile.vue')
// const Profile = () => import('@/views/pages/profile-new/Profile.vue') // ambil data efab
const Fab = () => import('@/views/pages/fab/Fab.vue')

const Error404 = () => import('@/views/error/Error404.vue')
const ComingSoon = () => import('@/views/error/ComingSoon.vue')
const Payment = () => import('@/views/pages/payment/Payment.vue')
const Transfer = () => import('@/views/pages/payment/Transfer.vue')

const PaymentSuccess = () => import('@/views/pages/payment/PaymentSuccess.vue')
const PaymentFailed = () => import('@/views/pages/payment/PaymentFailed.vue')

const Ntivi = () => import('@/views/pages/ntivi/Ntivi.vue')
const Ndrive = () => import('@/views/pages/ndrive/Ndrive.vue')

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: { name: 'login' },
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        layout: 'full',
      },
    },
    // {
    //   path: '/register',
    //   name: 'auth-register',
    //   component: Register,
    //   meta: {
    //     layout: 'full',
    //     resource: 'Auth',
    //     redirectIfLoggedIn: true,
    //   },
    // },
    {
      path: '/forgot-password',
      name: 'auth-forgot-password',
      component: ForgotPassword,
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard
    },
    {
      path: '/invoice',
      name: 'invoice',
      component: Invoice,
      meta: {
        pageTitle: 'Invoice',
        breadcrumb: [
          {
            text: 'Invoice',
            active: true
          }
        ],
      },
    },
    {
      path: '/faq',
      name: 'faq',
      component: Faq,
      meta: {
        pageTitle: 'FAQ',
        breadcrumb: [
          {
            text: 'Faq',
            active: true
          }
        ],
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true
          }
        ],
      },
    },
    {
      path: '/ticket',
      name: 'open-ticket',
      component: Ticket,
      meta: {
        pageTitle: 'Open Ticket',
        breadcrumb: [
          {
            text: 'Open Ticket',
            active: true
          }
        ],
      },
    },
    {
      path: '/fab',
      name: 'fab',
      component: Fab,
      meta: {
        pageTitle: 'FAB',
        breadcrumb: [
          {
            text: 'FAB',
            active: true
          }
        ],
      },
    },
    {
      path: '/ticket-history/:schedule_id',
      name: 'ticket-history',
      component: Tickethistory,
      meta: {
        pageTitle: 'History Ticket',
        breadcrumb: [
          {
            text: 'Open Ticket',
            to: '/ticket',
          },
          {
            text: 'History',
            active: true
          },
        ],
      },
    },
    {
      path: '/product-ticket/:action/:product_category_id/:text/:price_catalog_detail_id',
      name: 'product-ticket',
      component: Ticketproduct,
      meta: {
        pageTitle: 'Subscription',
        breadcrumb: [
          {
            text: 'My Subscription',
            to: '/subscription',
          },
          {
            text: 'Our Products',
            active: true
          },
          {
            text: 'Add Subscription',
            active: true,
          }
        ],
      },
    },
    {
      path: '/subscription',
      name: 'subscription',
      component: Subscribe,
      meta: {
        pageTitle: 'Subscription',
        breadcrumb: [
          {
            text: 'My Subscription',
            active: true,
          }
        ],
      },
    },
    {
      path: '/products/:id',
      name: 'products',
      component: Products,
      meta: {
        pageTitle: 'Subscription',
        breadcrumb: [
          {
            text: 'My Subscription',
            to: '/subscription',
          },
          {
            text: 'Our Products',
            active: true,
          }
        ],
      },
    },
    {
      path: '/payment',
      name: 'payment',
      component: Payment,
      meta: {
        pageTitle: 'Payment',
        breadcrumb: [
          {
            text: 'Payment',
            active: true
          }
        ],
      },
    },
    {
      path: '/my-products',
      name: 'my-products',
      redirect: { path: '/products/Add' }
    },
    {
      path: '/pages/knowledge-base',
      name: 'pages-knowledge-base',
      component: () => import('@/views/pages/knowledge-base/KnowledgeBase.vue'),
      meta: {
        pageTitle: 'Knowledge Base',
        breadcrumb: [
          {
            text: 'Pages',
          },
          {
            text: 'Knowledge Base',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pages/knowledge-base/:category',
      name: 'pages-knowledge-base-category',
      component: () => import('@/views/pages/knowledge-base/KnowledgeBaseCategory.vue'),
      meta: {
        pageTitle: 'Category',
        breadcrumb: [
          {
            text: 'Pages',
          },
          {
            text: 'Knowledge Base',
            to: '/pages/Knowledge-base',
          },
          {
            text: 'Category',
            active: true,
          },
        ],
        navActiveLink: 'pages-knowledge-base',
      },
    },
    {
      path: '/pages/knowledge-base/:category/:slug',
      name: 'pages-knowledge-base-question',
      component: () => import('@/views/pages/knowledge-base/KnowledgeBaseCategoryQuestion.vue'),
      meta: {
        pageTitle: 'Question',
        breadcrumb: [
          {
            text: 'Pages',
          },
          {
            text: 'Knowledge Base',
            to: '/pages/Knowledge-base',
          },
          {
            text: 'Category',
            to: '/pages/Knowledge-base/category',
          },
          {
            text: 'Question',
            active: true,
          },
        ],
        navActiveLink: 'pages-knowledge-base',
      },
    },
    {
      path: '/ntivi',
      name: 'ntivi',
      component: Ntivi,
      meta: {
        pageTitle: 'NTivi',
        breadcrumb: [
          {
            text: 'NTivi',
            active: true,
          }
        ],
      },
    },
    {
      path: '/ndrive',
      name: 'ndrive',
      component: Ndrive,
      meta: {
        pageTitle: 'NDrive',
        breadcrumb: [
          {
            text: 'NDrive',
            active: true,
          }
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: Error404,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/coming-soon',
      name: 'coming-soon',
      component: ComingSoon,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/payment-success',
      name: 'payment-success',
      component: PaymentSuccess,
      meta: {
        layout: 'full',
        guest: true
      },
    },
    {
      path: '/payment-failed',
      name: 'payment-failed',
      component: PaymentFailed,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen

router.beforeEach((to, _, next) => {
  // Remove initial loading
  // const appLoading = document.getElementById('loading-bg');
  // if (appLoading) {
  //   appLoading.style.display = 'none'
  // }
  useJwt.setToken(localStorage.getItem("token"));

  const token = localStorage.getItem('token');
  const publicPages = ['login', 'register', 'forgot-password', 'payment-success', 'payment-failed'];
  const extract = to.path.split('/');
  const authRequired = !publicPages.includes(extract[1]);
  if (authRequired && token === null) {
    alert('You not eligible to access this page!')
    next('/login')
  } else if (publicPages.includes(extract[1])) {
    next()
  } else {
    // next()
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    axios.get('/authentication/restricted/').then((response) => {
      if(response.message =="Expired token") {
        next('/login')
      } else {
        next()
      }
    })
    .catch(error => {
        alert('Your session has been expired')
        next('/login')
      })
    //next();
  }
});


router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router;
